import request from "./request";
import Vue from "vue";
import baseUrl from "./baseUrl";
import utils from "@/utils";
// this is for globally used APIs only

const url = baseUrl.notSandbox;

export function switchToken(params = {}, headers = {}) {
    return request.post(url + '/ba021/v1/org/switch-org', params, {headers})
}
export default {
  getUploadConfig: (params = {}) => request.post(url + "/aa038/v1/app/upload-config", params, { hideLoadingSpinner: true, headers: { 'x-upload-module': 'bo' } }),
    baseUrl: url,
    switchToken,
    login: (params = {}) => request.post(`${url}/ba021/v1/org/login`, params, { hideLoadingSpinner: false }),
    refreshToken: (rToken = '') => request.post(`${url}/ba021/v1/org/refresh-token`, {}, { hideLoadingSpinner: true, hideErrorMsg: true, refreshToken: rToken }),
    getUserAccounts: (params = {}, hideLoadingSpinner = true, token = '') => request.get(`${url}/ba021/v1/org/get-my-orgs`, { hideLoadingSpinner, headers: {'Authorization': `Bearer ${token}`}, }),
    logout: (params = {}) => request.post(`${url}/ba021/v1/logout`, params),
    pollQrLogin: (params = {}) => request.post(url + "/ba021/v1/org/qr-login", params, { hideLoadingSpinner: true }),
    async setDynamicToken(params = {}) {
        // make sure it's not set twice
        if (Vue.prototype.$fileAccessToKen) {
          return;
        }

        const tmpTokenResp = await request.post(url + "/ba021/v1/auth/dynamic", params, { hideLoadingSpinner: true });

        if (tmpTokenResp && tmpTokenResp.success) {
          Vue.prototype.$fileAccessToKen = tmpTokenResp.data.token;
        }
      },
  fileUpload: (params = {}) => {
    return utils.uploadFiles(params.files, 'files');
    },
    publicImageUpload: (params = {}) => {
      return utils.uploadFiles(params.files);
    },
    imageUpload: (params = {}) => {
        return utils.uploadFiles(params.files);
    },
    getMp: (params = '') => request.get(`${url}/ba021/v1/business-account/orgAccount/${utils.getLocal('tz-cortex-org-id', false)}`, { hideLoadingSpinner: true }),
    paymentAccounts: (param = {}) => request.get(baseUrl.main + "/fapi/mch-account/receiving-accounts?" + param),
    internalAccounts: (param = {}) => request.get(baseUrl.main + "/fapi/transfer/list-internal-source-accounts?" + param),
    merchantInfo: (param = {}) => request.get(baseUrl.main + "/fapi/user/info", param),
    orgDetail: (params = null) => request.get(url + `/ba021/v1/business-account/orgAccount/${params}`, { hideLoadingSpinner: true }),
    operators: (param = {}) => request.get(baseUrl.main + "/fapi/payment-method/list-operators", param),
    updateMp: (params = {}) => request.put(url + "/aa038/v1/mps/" + params._id, params),
    getUserAndOrg: (params = {}) => request.post(url + "/ba021/v1/org/my/info-with-org-account", params, { hideLoadingSpinner: true }),
    onEnablePaymentApproval: (params = {}) => request.post(url + "/ba021/v1/business-account/ctx/enableCtxPaymentApproval", params),
    onDisablePaymentApproval: (params = {}) => request.post(url + "/ba021/v1/business-account/ctx/disablePaymentApprovals", params),
    onUpdatePaymentApproval: (params = {}) => request.post(url + "/ba021/v1/business-account/ctx/updatePaymentApproval", params),
    getOrgAccountCtxSettings: orgId => request.get(url + `/ba021/v1/business-account/ctx/settings/${orgId}`, { hideLoadingSpinner: true }),
    getApproverList: (params = {}) => request.post(url + `/ba021/v1/org/approver-list`),

    getOrgIdByPhone: (params = {}) => request.post(url + "/ba021/v1/org/get-or-create-by-phone", params),
    registrationDocs: (params = {}) => request.post(url + "/ba021/v1/business-account/add-registration-docs", params),
    createOrgAccount: (params = {}) => request.post(url + "/ba021/v1/org/orgAccount/create", params),
    updateOrgAccount: (params = {}, extra = {}) => request.post(url + "/ba021/v1/business-account/update", params, extra),
    userLogin: (params = {}) => request.post(`${url}/ba021/v1/login`, params),
    userEmailLogin: (params = {}) => request.post(`${url}/ba021/v1/email-login`, params),
    signupWithEmail: (params = {}) => request.post(url + "/ba021/v1/merchant-email-signup", params),
    // signupWithEmail: (params = {}) => request.post(url + "/ba021/v1/merchant-email-signup/new", params),

    // sendEmailOtp: (params = {}) => request.post(url + "/ba021/v1/send/email-otp", params), // old method
    sendEmailOtp: (params = {}) => request.post(url + "/ba021/v1/send/email-otp", params),
    sendSmsOtp: (params = {}) => request.post(url + "/ba021/v1/sms/otp", params),
    // sendEmailOtpForgotPassword: (params = {}) => request.post("http://localhost:3006/ba021/v1/org/send/email-otp/forgot-password", params), // url +
    sendEmailOtpForgotPassword: (params = {}) => request.post(url +"/ba021/v1/org/send/email-otp/forgot-password", params), // url +
    validateOtp: (params = {}) => request.post(url + "/ba021/v1//verify-email-otp-and-return-user-if-exist", params),
    validateSmsOtp: (params = {}) => request.post(url + "/ba021/v1/sign-up/verify-otp", params),
    getTmpToken: (params = {}) => request.post(url + "/ba021/v1/auth/dynamic", params, { hideLoadingSpinner: true }),
    getMpConfig: () => request.get(url + '/aa038/v1/mps/config')
}