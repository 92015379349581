// const UPLOAD_ROOT = API_ROOT;
// const AUTH_API_ROOT = API_ROOT;
let env = process.env.NODE_ENV
// console.log(env)

const PROD = "https://api.tranzak.me";
const STAG = "https://staging-api.tranzak.me";
const DEV = "https://staging-api.tranzak.me";
const SANDBOX = "https://sandbox-api.tranzak.me";

let localEnv = localStorage.getItem('tz-cortex-env');
if(!localEnv || localEnv != 'sandbox'){
    localEnv = 'production';
}

localStorage.setItem('tz-cortex-env', localEnv);

if(env !== 'staging' && env != 'development'){
    env = localEnv
}

let baseUrl = PROD;

if (env === "staging") {
    baseUrl = STAG;
}

if (env === "development") {
    baseUrl = DEV;
}

if (env === "production") {
    baseUrl = PROD;
}

if (env === "sandbox") {
    baseUrl = SANDBOX;
}


export default {
    notSandbox: (env == 'production' || env == 'sandbox')? PROD: baseUrl,
    main: baseUrl,
    prod: PROD,
    envName: env,
    remote: env == 'production' ? PROD : STAG,
    localUrl:'http://localhost:3006'
};