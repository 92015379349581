import utils from '@/utils/index'
import router from "@/router/index";
import Vue from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import eventBus from "./event-bus";



const axios = require('axios')

let activePendingRequestWithLoader = 0;

const defaultExtraOptions = {
  hideLoadingSpinner: false,
  hideErrorMsg: false
};

const instance = axios.create({
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false
  // })
  baseURL: '',
  timeout: 15 * 1000
})

instance.interceptors.request.use(param => {
  // if(activePendingRequestWithLoader <= 0) eventBus.$emit('REQUEST_PENDING_INVOKE_LOADER', true);
  // activePendingRequestWithLoader += 1;

  if (param && param.data && param.data.isFormData) {
    const postData = new FormData()
    if (param.data) {
      const keys = Object.keys(param.data)
      for (let i = 0; i < keys.length; i++) {
        postData.append(keys[i], param.data[keys[i]])
      }
    }
    param.data = postData
  }
  return param
})

/**
 * Runs loader stuffs before the request is sended.
 *
 * @param {Boolean} hideLoadingSpinner indicates whether to hide the loader.
 * @param {Boolean} hideErrorMsg: false indicates whether to hide error messages.
 */
 const requestLoaderPreProcessor = (hideLoadingSpinner = false, hideErrorMsg = false) => {
  if(hideLoadingSpinner) return;

  if (activePendingRequestWithLoader <= 0) eventBus.$emit('REQUEST_PENDING_INVOKE_LOADER', true);
  activePendingRequestWithLoader += 1;
};

/**
 * Runs loader stuffs after the request is completed.
 *
 * @param {Boolean} hasHideLoader indicates whether the request required to hide the loader..
 */
const requestLoaderPostProcessor = (hasHideLoader = false) => {
  if(hasHideLoader) return;

  const val = activePendingRequestWithLoader - 1;

  if (val <= 0) {
    eventBus.$emit('REQUEST_PENDING_INVOKE_LOADER', false);
  }

  activePendingRequestWithLoader -= 1;
};


/**
 * Handles a response from backend.
 *
 * @param {Object} response Request response
 * @param {Object} response.data Request response data.
 * @param {String} response.data.errorMsg Request error message.
 * @param {String} path Request url
 * @param {Object} data Data used to perform the request
 * @param {Object} loadingInfo Information on loader
 *
 * @returns Resquest data response
 */
const processResponse = function (response, path, data, loadingInfo) {

  requestLoaderPostProcessor(loadingInfo?.hideLoadingSpinner)
  /**
   * Handle api error.
   */
   if (response && response.data && (response.data.errorMsg || response.data.errorCode)) {
    if (response.data.errorCode == 40001 || response.data.errorCode == 40002 || response.data.errorCode == 40401) {

      const prefix = location.href.includes('staging') || location.href.includes('localhost') ? "https://staging-" : "https://";

      if(location.pathname != '/login'){
        router.push('/login?next=' + location.href);
      }

      return;
    }

    if(!loadingInfo?.hideErrorMsg){
      const vm = new Vue({})

      vm.$bvToast.toast(response.data.errorMsg || response.data.errorMsg.error, {
        title: 'Error',
        solid: false,
        toaster: 'b-toaster-top-center',
        variant: 'primary',
        autoHideDelay: 20000
      })
    }

  }
  /**
   * Return response.
   */
  return response.data
}

const processError = function (responseError, path, loadingInfo) {
  requestLoaderPostProcessor(loadingInfo?.hideLoadingSpinner)
}

const getRequestConfig = function (params, extra = {}) {
  // hide loading
  if (extra.hideLoadingSpinner) {
    // handleLoading(false);
  } else {
    // handleLoading(true);
  }

  const env = process.env.NODE_ENV

  const exitingToken = localStorage.getItem('tz-cortex-token');
  const requestToken = "Bearer " + (exitingToken ? exitingToken.replaceAll('"', '') : '');

  let orgId = localStorage.getItem('tz-cortex-org-id') || '';

  let regex = new RegExp(`"|'`, 'g');
  orgId = orgId.replace(regex, '');

  const extraConfig = {
    ...extra?.headers
  };
  if(extra.refreshToken) {
    extraConfig['x-refresh-token'] = `Bearer ${extra.refreshToken}`;
  }
  // config
  return {
    baseURL: '',
    headers: {
      'X-Force-Object': '0',
      'X-Platform': 'H5',
      'X-System': '',
      'X-Brand': '',
      'X-Model': '',
      'X-Language':  i18n.locale || 'en',
      'X-Network': '',
      'X-Lat': '',
      'X-Lon': '',
      'X-App-ID': "",
      'X-Org-Id': orgId,
      "X-Nonce": "",
      "Authorization": requestToken,
      "X-Platform-Id": "9rem6jwjc3a5",
      "X-App-Market": "",
      "X-Tz-Distinct-Id": utils.getDistinctId(),
      ...extraConfig
    }, // `params` are the URL parameters to be sent with the request // `headers` are custom headers to be sent
    // Must be a plain object or a URLSearchParams object
    // params: {},
    data: {},
    timeout: 60 * 1000, // You may modify the headers object. // 默认30秒。可以按照具体业务修改timeout 值
    responseType: 'json'
  } // default
}

const unkownResult = {
  err: {
    ec: '0',
    em: '未知错误'
  },
  isUnkownError: true
}

export default {
  post: function (path, data, extra = { hideLoadingSpinner: false, hideErrorMsg: false, refreshToken: '' }) {
    requestLoaderPreProcessor(extra?.hideLoadingSpinner, extra?.hideErrorMsg)
    return instance.post(path, data, getRequestConfig({}, extra)).then(response => {
      const result = processResponse(response, path, data, extra)
      if (result && result.err) {
        throw result
      } else if (result) {
        return result
      } else {
        return {}
      }
    })
      .catch(err => {
        console.log('-----||||||||||||||err--api||||||||||------>>>--', err)
        processError(err, path, extra);
        throw unkownResult;
      })
  },
  put: function (path, data, extra = { hideLoadingSpinner: false, hideErrorMsg: false, refreshToken: '' }) {
    requestLoaderPreProcessor(extra?.hideLoadingSpinner)
    return instance.put(path, data, getRequestConfig({}, extra)).then(response => {
      const result = processResponse(response, path, data, extra)
      if (result && result.err) {
        throw result
      } else if (result) {
        return result
      } else {
        return {}
      }
    })
      .catch(err => {
        console.log('-----||||||||||||||err--api||||||||||------>>>--', err)
        processError(err, path, extra);
        throw unkownResult;
      })
  },
  get: function (path, extra = { hideLoadingSpinner: false, hideErrorMsg: false, refreshToken: '' }) {
    requestLoaderPreProcessor(extra?.hideLoadingSpinner)
    return instance
      .get(path, getRequestConfig({}, extra))
      .then(response => {
        const result = processResponse(response, path, {}, extra)
        if (result.err) {
          throw result
        } else {
          return result
        }
      })
      .catch(err => {
        processError(err, path, extra);
        throw unkownResult
      })
  }
}
