<template>
  <div>
    Yoo
    <div v-html="body"></div>
  </div>
</template>
<script>


import Utils from  '@/utils/index';

export default {
  name: 'Main',
  components:{
     
  },
  props: {
    json: {
      type: Object,
      default: ()=> {}
    },
  },
  data() {
      return{
        body: '',
      }
  },
  async mounted() {
    

      this.body = await this.processData(this.json);
  },
  methods: {
    async processData(obj){
      console.log(obj)
      let result = ``;
      const keys = Object.keys(obj)
      console.log('obj')
      for(let i=0; i<keys.length; i++){
        result += `<div class="tz-json-wrap">
            <div class="tz-json-title">${keys[i]}</div>`;
            if(obj[keys[i]] && this.getType(obj[keys[i]]) == 'object'){
              result += `<div class="tz-json-value">`;
              result+= this.processData(obj[keys[i]]);
              result += `</div>`;
            }else if(this.getType(obj[keys[i]]) == 'array'){
              console.log('found array: ', obj[keys[i]])
              result += `<div class="tz-json-value hh">`;
              obj[keys[i]].forEach(async (item, j) => {
                if(item && this.getType(item) == 'object'){
                  const tmpData = await this.processData(item);
                  console.log('found sub object: ', tmpData)
                  result+= `${tmpData}`;
                }
                else {
                  result += `<span class="font-weight-bold">${item} ${j < obj[keys[i]].length - 1? ', ':''}</span>`
                }
              });
              result+= `</div>`
            }else{
              console.log('others')
              result+= `<div class="tz-json-value">${obj[keys[i]]}</div>`
            }
            result+= `</div>`;
      }
      return result;
    },
    getType(data){
      console.log('data here', data)
      if(Array.isArray(data)) return 'array';
      return typeof data;
    }
  }
}
</script>
<style lang="scss" scope="">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .hover-reduce:hover{
      opacity: .7;
  }
  .pt{
      padding-top: 7.5px !important;
  }
  .height-fill{
      height: 80%;
      min-height: 80%;
  }
  .color-light{
      color: #fff !important;
  }
  .txn-logos{
      margin-bottom: 3px;
      width: 40px;
      border-radius: 2px;
  }
  .payment-provider img{
      width: 30px;
  }
  .tz-json-wrap{
    display: flex;
    .tz-json-title{
      color: #990099;
      &:after{
        display: inline-block;
        margin-left: 10px;
        color: black;
        font-weight: bolder;
        content: "=>"
      }
    }
    .tz-json-value{
      padding-left: 10px;
    }
  }
</style>