import moment from 'moment'
// this is for globally used APIs only
import AWS from 'aws-sdk';


moment.locale('en-gb');

const makeDateUtc = value => { // take a given time and makes it utc time for the same date
    if(!value) return '';
    if(!value.toLowerCase().includes('t')){
        value = value.replace(' ', 'T')+'Z';
        value = new Date(value);
        // value.setUTCHours(value.getHours(), value.getMinutes());
        // value.setUTCFullYear(value.getFullYear());
        // value.setUTCMonth(value.getMonth());
    }
    return value;
}

export const tzLogo = 'https://fms.tranzak.me/cdn/pf/ie4xzt8y24d5ole4_undefined107055.3011288922tranzak.png';

const CDN_BASE_URL = "https://cdn.tranzak.app/";

const _uploadFile = async (file = {}, folderName = 'images/') => { // folderName can images, videos,  files(pdf, docs) , other(for any other type),
    let uploadKeys = localStorage.getItem('tz-upload-keys');
    uploadKeys = uploadKeys ? JSON.parse(uploadKeys) : {};

    AWS.config.update({
        accessKeyId: uploadKeys.accessKeyId, //'AKIA4MMCIO55WVFPUEME',
        secretAccessKey: uploadKeys.secretAccessKey,// "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",
        region: uploadKeys.region //'eu-west-3'
    });

    const s3 = new AWS.S3();
    const path = folderName + file.name;
    const bucketName = "cdn.tranzak.app";


    const params = {
        Bucket: bucketName,
        Key: path, // File name you want to save as in S3
        Body: file
    };

    // Uploading files to the bucket
    return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
            if (err) {
                console.log("Error==================>>>>>>>>>>>>>", err);
                reject(err);
            } else {

                console.log("Upload Success", data);
                resolve({
                    path: CDN_BASE_URL + path
                });
            }
        });
    });
}

export const arrayEquality = (a, b)=> {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

export const urlValidator = url=>{
    try{
        const link = new URL(url);
        return true;
    }catch(e){
        return false;
    }
}

export const orderArrayObjectAlphabetically = function(array, key) {
    return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

export default {
    getLocal (key = '', isStringifiedContent = true) {
        try{
            const item = localStorage.getItem(key);
            if (isStringifiedContent) return JSON.parse(item);
            return item;
        }catch(e){
            return null
        }
    },
    setLocal(key = '', data = '',  isStringifiedContent = true) {
        if (isStringifiedContent) data = JSON.stringify(data);
        localStorage.setItem(key, data);

    },
    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    formatCurrency(value, currency = 'XAF', language = 'en-US') {
        //currency eg USD
        //language eg fr-FR, en-US
        return new Intl.NumberFormat(language, { style: 'currency', currency: currency }).formatToParts(value).map(
            p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
        ).join('');
    },

    formatNumber: function (n) {
        const str = n.toString();
        return str[1] ? str : `0${str}`;
    },
    isMtn (phone) {
        return /^67/.test(phone) || /^65[0-4]/.test(phone) || /^68[0-3]/.test(phone);
    },
    isOrange (phone) {
        return /^69/.test(phone) || /^65[5-9]/.test(phone) || /^68[6-9]/.test(phone);
    },
    getQueryParams: () => {
        var params = {};
        location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            if (value === "undefined") {
                value = "";
            }

            params[key] = decodeURIComponent(value);
        });

        // noCache
        if (params.noCache) {
            // clear cache
            this.xwsStorage.clearAll();
        }

        if (params.resetAuth) {
            // clear resetAuth
            this.xwsStorage.clear("token");
            this.xwsStorage.clear("xwsUserInfo");
        }

        return params;
    },
    loadScript: function (path, callback, scriptId) {
        scriptId = scriptId || "external-" + Date.now();
        setTimeout(() => {
            ((d, s, id) => {
                var js;
                var fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.onload = function () {
                    if (typeof callback === "function") callback();
                };
                js.src = path;
                js.type = "text/javascript";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", scriptId);
        }, 300);
    },
    localUserInfo: {
        get: () => {
            const result = localStorage.getItem("xwsUserInfo");

            return result ? JSON.parse(result) : {};
        },
        set: user => {
            localStorage.setItem("xwsUserInfo", JSON.stringify(user));
        },
        clearToken: () => {
            // localStorage.removeItem(TOKEN_STORAGE_KEY);
        }
    },
    getUUID: function () {
        const id =
            Math.random()
                .toString(36)
                .substring(5) +
            Date.now() +
            Math.floor(1000000 * Math.random()).toString();

        return id;
    },
    getDistinctId: function () {
        let xwsGlobalDistinctId = localStorage.getItem("tzDistinctId");

        if (!xwsGlobalDistinctId) {
            xwsGlobalDistinctId = this.getUUID();
            localStorage.setItem("tzDistinctId", xwsGlobalDistinctId);
        }

        return xwsGlobalDistinctId;
    },
    numberWithUnit: (num = 0, decimal = 1) => {
        if (num >= 10000) {
            return (num / 10000).toFixed(decimal) + "W";
        }
        return num.toFixed(0);
    },
    formatDate: value => {
        value = makeDateUtc(value);
        return moment(value).format('lll')
    },
    formatDateOnly: value => {
        value = makeDateUtc(value);
        return moment(value).format('L')
    },
    dashboardDate: (value, format = 'LT') => {
        // moment.locale('fr');
        value = makeDateUtc(value)
        if (format !== 'LT') {
            return moment(value).format('L').substring(0, 5)
        }
        // format for last 24 hours
        return moment(value).format(format)
    },
    // makeDateUtc(value){ // take a given time and makes it utc time for the same date
    //     if(!value.toLowerCase().includes('z') && !value.toLowerCase().includes('t')){
    //         value = new Date(value);
    //         value.setUTCHours(value.getHours(),value.getMinutes());
    //         value.setUTCFullYear(value.getFullYear());
    //         value.setUTCMonth(value.getMonth());
    //     }
    //     return value;
    // },
    dateFormat: (value, format = 'lll') => {
        value = makeDateUtc(value);
        return moment(value).format(format)
    },
    dateFormatFromNow: value => {
        value = makeDateUtc(value);
        return moment(value).fromNow();
    },
    convertToUTC(date, time = '00:00:00') { // takes a give time (utc + 1) and makes it utc
        return new Date(new Date(date + ' ' + time)).toISOString()
    },
    coverNum: num => {
        if (isNaN(num) || parseInt(num) < 0) {
            return "00";
        }
        if (parseInt(num) < 10) {
            return "0" + num;
        }
        return num;
    },
    getInfo: function () {
        return JSON.parse(localStorage.getItem('merchantInfo')) || undefined;
    },
    isValidCmNumber: number => {
        return /^6[256789]\d{7}$/.test(number);
    },
    isValidUid: (uid = '')=> {
        const regExp =  /^\w+$/;
        if ((uid.length >= 14 && uid.length <= 22) && (regExp.test(uid))) {
            return true;
        }
        return false;
    },
    completeTable(items = []) {
        if (items.length >= 10) return items;
        const loop = 10 - items.length;
        for (let i = 0; i < loop; i++) {
            items.push({});
        }
        return items;
    },
    isValidCard(card) {
        card = card.toString();
        let numbers = [];
        for (let i = card.length - 2; i >= 0; i -= 2) {
            const index = parseInt(card[i]) * 2;
            numbers.unshift(parseInt(card[i + 1]));
            if (index.toString().length > 1) {
                numbers.unshift(index.toString().split('').reduce((e, r) => { return parseInt(e) + parseInt(r) }));
            } else {
                numbers.unshift(index);
            }
        }
        return numbers.reduce((e, r) => { return parseInt(e) + parseInt(r) }) % 10 === 0;
    },
    rearrangeListLength: (list, preferredListLength = 12) => {
        const len = list && list.length ? list.length : 0;
        // const len = list.length;
        if (len <= preferredListLength) {
            return list;
        } else {
            const filteredDate = [];
            // divide number of dates into equal halves as specified by preferredListLength
            const intervals = Math.ceil(len / preferredListLength);
            let counter = 0;
            for (let i = 0; i < len; i = intervals * counter) {
                // ensure the last date is always the last element in the list
                if (i >= len - intervals) {
                    filteredDate[counter] = list[len - 1];
                    counter++;
                    break;
                }
                filteredDate[counter] = list[i];
                counter++;
            }
            return filteredDate;
        }
    },
    groupDashboardData: (list, dates, duration = 1) => { // duration 1 = same day, 2 same hour
        // console.log(dates);
        const len = list && list.length ? list.length : 0;
        // const len = list.length;
        let newList = [];
        let newTimeList = [];
        if (duration == 1) {
            if (len >= 24) {
                for (let i = 0; i < len; i++) {
                    let time = dates[i].split(' ')[1].substring(0, 5);
                    if (i == 0) {
                        newTimeList.push(time);
                        newList.push(list[i]);

                    } else {
                        let position = -1;
                        newTimeList.filter((e, index) => {
                            if (e.split(':')[0] == time.split(':')[0]) {
                                position = index;
                            }
                            return e.split(':')[0] == time.split(':')[0];
                        });
                        if (position >= 0) {
                            newList[position] = Number(newList[position]) + Number(list[i]);
                            // console.log("time: ", time, "position: ", position, "list: ", list[i], "i: ", i);
                        } else {
                            newTimeList.push(time);
                            newList.push(list[i]);
                        }
                    }
                }
            } else {
                newList = list;
                newTimeList = dates.map(date => date.split(' ')[1].substring(0, 5));
            }
            return { data: newList, time: newTimeList }
        }

        if (duration > 1 && duration < 5) { // last 3, 7 and 30 days
            // const last3DaysLen = 72;
            // const last7DaysLen = 7;
            // const last30DaysLen = 30;
            let length = 0;
            switch (duration) {
                case 2:
                    length = 72;
                    break;
                case 3:
                    length = 7;
                    break;
                default:
                    length = 30;
                    break
            }
            if (len >= length) {
                for (let i = 0; i < len; i++) {
                    let time = dates[i].split(' ')[0];
                    if (i == 0) {
                        newTimeList.push(time);
                        newList.push(list[i]);

                    } else {
                        const dateExist = newTimeList.filter(e => e == time);
                        if (dateExist.length) {
                            const position = newTimeList.indexOf(time);
                            newList[position] = Number(newList[position]) + Number(list[i]);
                            // console.log("time: ", time, "position: ", position, "list: ", list[i], "i: ", i);
                        } else {
                            newTimeList.push(time);
                            newList.push(list[i]);
                        }
                    }
                }
            } else {
                newList = list;
                newTimeList = dates.map(date => date.split(' ')[0]);
            }
            return { data: newList, time: newTimeList }
        }

        if (duration == 5) { // all time
            if (len >= 12) {
                for (let i = 0; i < len; i++) {
                    let time = dates[i].split(' ')[0].substring(0, 7);
                    if (i == 0) {
                        newTimeList.push(time);
                        newList.push(list[i]);

                    } else {
                        const dateExist = newTimeList.filter(e => e == time);
                        if (dateExist.length) {
                            const position = newTimeList.indexOf(time);
                            newList[position] = Number(newList[position]) + Number(list[i]);
                            // console.log("time: ", time, "position: ", position, "list: ", list[i], "i: ", i);
                        } else {
                            newTimeList.push(time);
                            newList.push(list[i]);
                        }
                    }
                }
            } else {
                newList = list;
                if (dates && dates.length) {
                    newTimeList = dates.map(date => date.split(' ')[0]);
                }
            }
            return { data: newList, time: newTimeList }
        }
    },
    // goToDeveloper() {
    //     const prefix = location.href.includes('staging') ? "https://staging-developer.tranzak.me" : (location.href.includes('localhost') ? "http://localhost:9084" : "https://developer.tranzak.me");
    //     let token = localStorage.getItem('tz-cortex-token');
    //     if (token) {  // remove quotes from token
    //         token = token.replaceAll("'", '');
    //         token = token.replaceAll('"', '');
    //     }
    //     let userData = {};
    //     let data = localStorage.getItem('tz-cortex-user');
    //     if(data && data != 'undefined')  userData = JSON.parse(data)
    //     const role = JSON.parse(localStorage.getItem('tz-cortex-org-role')) || '';
    //     const locale = localStorage.getItem('locale');
    //     return prefix + `?oId=${userData.orgId}&botk=${encodeURI(btoa(token))}&lang=${encodeURI(locale)}&mdn=${encodeURI(userData.mDName)}&mn=${encodeURI(userData.mName)}&mId=${encodeURI(userData.mId)}&r=${encodeURI(userData.role)}`;
    // },
    goToOtherServices(url ='') {
        // const prefix = location.href.includes('staging') ? "https://staging-developer.tranzak.me" : (location.href.includes('localhost') ? "http://localhost:9084" : "https://developer.tranzak.me");
        let token = localStorage.getItem('tz-cortex-token');
        let refreshToken = localStorage.getItem('tz-cortex-refresh-token');
        let mode = localStorage.getItem('Sanaga-skin') || 'light';
        if (token) {  // remove quotes from token
            token = token.replaceAll("'", '');
            token = token.replaceAll('"', '');
        }
        if (refreshToken) {  // remove quotes from token
            refreshToken = refreshToken.replaceAll("'", '');
            refreshToken = refreshToken.replaceAll('"', '');
        }
        let userData = {};
        let org = {};
        let data = localStorage.getItem('tz-cortex-user');
        if(data && data != 'undefined')  userData = JSON.parse(data)
        data = localStorage.getItem('tz-cortex-org-user');
        if(data && data != 'undefined')  org = JSON.parse(data)
        const role = JSON.parse(localStorage.getItem('tz-cortex-org-role')) || '';
        const locale = localStorage.getItem('locale');
        return url + `?oId=${userData.orgId}&botk=${encodeURI(btoa(token))}&rftk=${encodeURI(btoa(refreshToken))}&lang=${encodeURI(locale)}&mdn=${encodeURI(org.mDName)}&mn=${encodeURI(org.mName)}&mId=${encodeURI(org.merchantId)}&r=${encodeURI(userData.role)}&mode=${mode}`;
    },
    oneStopPortal: location.href.includes('staging') ? "https://staging-myapps.tranzak.me" : (location.href.includes('localhost') ? "http://localhost:9080" : "https://myapps.tranzak.me"),
    developerPortal: location.href.includes('staging') ? "https://staging-developer.tranzak.me" : (location.href.includes('localhost') ? "http://localhost:9084" : "https://developer.tranzak.me"),
    messagingPortal: location.href.includes('staging')  || location.href.includes('localhost')? "https://staging-messaging.tranzak.me" : "https://messaging.tranzak.me",
    goToOneStopPortal(r = '') { // r for specified route
        let userData = {};
        let data = localStorage.getItem('tz-cortex-user');
        if(data && data != 'undefined')  userData = JSON.parse(data)
        const path = '/partner/' + userData.orgId + r + '/';

        // const url = location.href.includes('staging') ? "https://staging-myapps.tranzak.me" : (location.href.includes('localhost') ? "http://localhost:9080" : "https://myapps.tranzak.me");
        return this.goToOtherServices(this.oneStopPortal + path)
    },
    goToDeveloper(r = '') { // r for specified route
        let userData = {};
        let data = localStorage.getItem('tz-cortex-user');
        if(data && data != 'undefined')  userData = JSON.parse(data)
        const path = '/partner/' + userData.orgId + r + '/';

        // const url = location.href.includes('staging') ? "https://staging-myapps.tranzak.me" : (location.href.includes('localhost') ? "http://localhost:9080" : "https://myapps.tranzak.me");
        return this.goToOtherServices(this.developerPortal + path)
    },
    goToMessaging(r = '') { // r for specified route
        let userData = {};
        let data = localStorage.getItem('tz-cortex-user');
        if(data && data != 'undefined')  userData = JSON.parse(data)
        const path = '/partner/' + userData.orgId + r + '/';

        // const url = location.href.includes('staging') ? "https://staging-myapps.tranzak.me" : (location.href.includes('localhost') ? "http://localhost:9080" : "https://myapps.tranzak.me");
        return this.goToOtherServices(this.messagingPortal + path)
    },
    getRoleNameByRoleId: roleId => {
        if (roleId == 1000) return 'Super Admin'
        if (roleId == 900) return 'Admin'
        if (roleId == 130) return 'Manager'
        if (roleId == 125) return 'Data Analyst'
        if (roleId == 120) return 'Support'
        if (roleId == 110) return 'Developer'
        if (roleId == 105) return 'Staff'
        if (roleId == 100) return 'Guest'

        return '';
    },
    getUserData: () => {
        const userData = localStorage.getItem('tz-cortex-user');
        if(userData && userData != 'undefined') return JSON.parse(userData)
        return null
    },
    getOrgData: () => {
        const userData = localStorage.getItem('tz-cortex-org-user');
        if(userData && userData != 'undefined') return JSON.parse(userData)
        return null
    },
    validateEmail: (inputText = '')=> {
        var format = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (inputText.match(format)) {
            console.log('Passed')
            return true;
        }
        return false;
    },
    getToken(){
        const exitingToken = localStorage.getItem('tz-cortex-token');
        return (exitingToken ? exitingToken.replaceAll('"', '') : '');
    },

    uploadFiles: async (files, folderName = 'images/') => { // folderName can images, videos,  files(pdf, docs) , other(for any other type),
        const uploadPromises = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            uploadPromises.push(_uploadFile(file));
    }

        try {
            const promiseResults = await Promise.all(uploadPromises);
            const results = { data: { path: [] }, success: true };

            promiseResults.forEach(result => {
                console.log('Upload Success:', result);
                results.data.path.push(result.path);
            });

            console.log("resultsresultsresults ", results);

            return results;
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    }

};