export default [
  {
    path: '/apps/calendar',
    name: 'calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER MANAGER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  {
    path: '/apps/workflow/approvals/progress/:workflowId',
    name: 'approvalsProgress',
    props: true,
    component: () => import('@/views/apps/approvals/ApprovalSteps.vue'),
  },
  {
    path: '/apps/workflow/approvals/list',
    name: 'approvalsList',
    component: () => import('@/views/apps/approvals/ApprovalList.vue'),
  },
  // Real name verification
  {
    path: '/apps/users/real-name-verification/list',
    name: 'users-real-name-verification-list',
    component: () => import('@/views/apps/user/real-name-verification/list/List.vue'),
  },

  // ACTIONS TAB STARTS HERE
  {
    path: '/transfer',
    redirect: '/transfer/all'
  },
  {
    path: '/invoice/pay',
    redirect: '/invoice'
  },
  {
    path: '/transactions',
    redirect: '/transactions/all'
  },
  {
    path:  '/apps/settlements',
    redirect: '/apps/settlements/all'
  },
  {
    path: '/apps/developer',
    redirect: '/apps/developer/api',
  },
  {
    path: '/transfer/all',
    name: 'transfer-all',
    component: () => import('@/views/apps/transfer/all/Main.vue'),
  },
  {
    path: '/transfer/to-linked-account',
    name: 'transfer-to-linked-account',
    component: () => import('@/views/apps/transfer/to-linked-account/Main.vue'),
  },
  {
    path: '/transfer/to-mobile-money-account',
    name: 'transfer-to-mobile-money-account',
    component: () => import('@/views/apps/transfer/to-mobile-money-account/Main.vue'),
  },
  {
    path: '/transfer/to-bank-account',
    name: 'transfer-to-bank-account',
    component: () => import('@/views/apps/transfer/to-bank-account/Main.vue'),
  },
  {
    path: '/transfer/to-prepaid-card',
    name: 'transfer-to-prepaid-card',
    component: () => import('@/views/apps/transfer/to-prepaid-card/To-prepaid-card.vue'),
  },
  {
    path: '/transfer/to-tranzak-user',
    name: 'transfer-to-tranzak-user',
    component: () => import('@/views/apps/transfer/to-tranzak-user/Main.vue'),
  },

  {
    path: '/transfer/:id',
    name: 'transfer-detail',
    component: () => import('@/views/apps/transfer/view/Main.vue'),
  },

  {
    path: '/apps/new-nav-1/sub-nav-2',
    name: 'new-nav-1-sub-nav-2',
    component: () => import('@/views/apps/new-nav-1/sub-nav-2/Main.vue'),
  },
  {
    path: '/apps/new-nav-2',
    name: 'new-nav-2',
    component: () => import('@/views/apps/new-nav-2/Main.vue'),
  },
  // Invoice
  {
    path: '/invoice',
    redirect: '/invoice/all',
  },
  {
    path: '/invoice/view',
    redirect: '/invoice/all',
  },
  {
    path: '/invoice/edit',
    redirect: '/invoice/all',
  },
  {
    path: '/invoice/all',
    name: 'invoice-all',
    component: () => import('@/views/apps/invoice/all/Main.vue'),
  },
  {
    path: '/invoice/create',
    name: 'invoice-create',
    component: () => import('@/views/apps/invoice/create/Main.vue'),
  },
  {
    path: '/invoice/upload',
    name: 'invoice-upload',
    component: () => import('@/views/apps/invoice/upload/Main.vue'),
  },
  {
    path: '/invoice/view/:id',
    name: 'invoice-view',
    component: () => import('@/views/apps/invoice/view/Main.vue'),
  },
  {
    path: '/invoice/view-request/:id',
    name: 'invoice-view-request',
    component: () => import('@/views/apps/invoice/view-request/Main.vue'),
  },
  {
    path: '/invoice/edit/:id',
    name: 'invoice-edit',
    component: () => import('@/views/apps/invoice/edit/Main.vue'),
  },
  {
    path: '/invoice/payments',
    name: 'invoice-payments',
    component: () => import('@/views/apps/invoice/payments/Main.vue'),
  },
  {
    path: '/invoice/requests',
    name: 'invoice-requests',
    component: () => import('@/views/apps/invoice/requests/Main.vue'),
  },
  {
    path: '/invoice/create-request',
    name: 'invoice-create-request',
    props: true,
    component: () => import('@/views/apps/invoice/create-request/Main.vue'),
  },
  {
    path: '/invoice/pay/:id',
    name: 'invoice-pay',
    component: () => import('@/views/apps/invoice/pay/Main.vue'),
  },
  {
    path: '/mass-payments',
    redirect: '/mass-payments/all',
  },
  {
    path: '/mass-payments/all',
    name: 'mass-payments-all',
    component: () => import('@/views/apps/masspayments/all/Main.vue'),
  },
  {
    path: '/mass-payments/recipients-detailes',
    name: 'mass-payments-recipients-detailes',
    component: () => import('@/views/apps/masspayments//List-recipients-and-status/RecipientsAndStatus.vue'),
  },
  {
    path: '/mass-payments/recipients/:id',
    name: 'mass-payments-recipients',
    component: () => import('@/views/apps/masspayments/List-recipients-and-amounts/RecipientsAndAmounts.vue'),
  },
  {
    path: '/mass-payments/edit',
    redirect: '/mass-payments'
  },
  {
    path: '/mass-payments/edit/:id',
    name: 'mass-payments-edit',
    props: true,
    component: () => import('@/views/apps/masspayments/edit/Main.vue'),
  },
  {
    path: '/mass-payments/create',
    name: 'mass-payments-create',
    props: true,
    // component: () => import('@/views/apps/masspayments/create/Main.vue'),
    component: () => import('@/views/apps/masspayments/create/createMassPayment.vue'),
  },
  {
    path: '/profiles',
    redirect: '/profiles/groups'
  },
  {
    path: '/profiles/groups',
    name: 'profiles-groups',
    // component: () => import('@/views/apps/masspayments/create/Main.vue'),
    component: () => import('@/views/apps/masspayments/groups/Groups.vue'),
  },
  {
    path: '/profiles/recipients',
    name: 'profiles-recipients',
    // component: () => import('@/views/apps/masspayments/create/Main.vue'),
    component: () => import('@/views/apps/masspayments/recipients/Main.vue'),
  },
  {
    path: '/mass-payments/upload',
    name: 'mass-payments-upload',
    component: () => import('@/views/apps/masspayments/upload/Main.vue'),
  },
  {
    path: '/mass-payments/:id',
    name: 'mass-payments-view',
    component: () => import('@/views/apps/masspayments/view/Main.vue'),
  },
  {
    path: '/settings/payment-methods',
    name: 'settings-payment-methods',
    component: () => import('@/views/apps/paymentmethods/Main.vue'),
  },
  {
    path: '/gift-cards',
    name: 'engagement-gift-cards',
    component: () => import('@/views/apps/engage/giftcards/Main.vue'),
  },
  {
    path: '/lucky-money',
    name: 'engagement-lucky-money',
    component: () => import('@/views/apps/engage/luckymoney/Main.vue'),
  },
  {
    path: '/coupons',
    name: 'engagement-coupons',
    component: () => import('@/views/apps/engage/coupons/Main.vue'),
  },
  {
    path: '/apps/account/settings',
    name: 'account-settings',
    component: () => import('@/views/apps/account/settings/Main.vue'),
  },
  {
    path: '/apps/account/app-settings',
    name: 'account-app-settings',
    component: () => import('@/views/apps/account/app-settings/Main.vue'),
  },
  {
    path: '/apps/account/general',
    name: 'account-general',
    component: () => import('@/views/apps/account/general/Main.vue'),
  },
  {
    path: '/account/balance',
    name: 'account-balance',
    component: () => import('@/views/apps/account/balance/Main.vue'),
  },
  {
    path: '/account/withheld-balance',
    name: 'account-withheld-balance',
    component: () => import('@/views/apps/account/balance/Withheld.vue'),
  },
  {
    path: '/settings/security',
    name: 'settings-security',
    component: () => import('@/views/pages/SecuritySettings/SecuritySettings.vue'),
  },
  {
    path: '/apps/account/referrals',
    name: 'account-referrals',
    component: () => import('@/views/apps/account/referrals/Main.vue'),
  },
  {
    path: '/transactions/all',
    name: 'transactions-all',
    component: () => import('@/views/apps/transactions/all/Main.vue'),
  },
  {
    path: '/transactions/automatic-settlement',
    name: 'transactions-automatic-settlement',
    component: () => import('@/views/apps/transactions/all/AutomaticSettlement.vue'),
  },
  {
    path: '/transactions/outbound',
    name: 'transactions-outbound',
    component: () => import('@/views/apps/transactions/all/Outbound.vue'),
  },
  {
    path: '/transactions/inbound',
    name: 'transactions-inbound',
    component: () => import('@/views/apps/transactions/all/Inbound.vue'),
  },
  {
    path: '/transactions/sales',
    name: 'transactions-sales',
    component: () => import('@/views/apps/transactions/all/Sales.vue'),
  },
  {
    path: '/transactions/cash-outs',
    name: 'transactions-cash-outs',
    component: () => import('@/views/apps/transactions/all/Cash-outs.vue'),
  },
  {
    path: '/transactions/settlements',
    name: 'transactions-settlements',
    component: () => import('@/views/apps/transactions/settlements/Main.vue'),
  },
  // {
  //   path: '/transactions/bonus-rewards',
  //   name: 'transactions-bonus-rewards',
  //   component: () => import('@/views/apps/transactions/bonus-rewards/Main.vue'),
  // },
  {
    path: '/transactions/details/:id?',
    name: 'transactions-details',
    component: () => import('@/views/apps/transactions/all/Details.vue'),
    props: true
  },
  {
    path: '/transactions/refunds',
    name: 'transactions-refunds',
    component: () => import('@/views/apps/merchant/refunds/Main.vue'),
  },
  {
    path: '/transactions/automatic-refunds',
    name: 'transactions-automatic-refunds',
    component: () => import('@/views/apps/merchant/refunds/AutomaticRefunds.vue'),
  },
  {
    path: '/transactions/details',
    redirect: '/transactions/',
  },
  {
    path: '/settings',
    redirect: '/settings/settlement',
  },
  {
    name: 'settings-developer',
    path: '/settings/developer',
    component: () => import('@/views/settings/Developer.vue'),
  },
  // {
  //   path: '/transactions/:id',
  //   name: 'transactions-details',
  //   component: () => import('@/views/apps/transactions/all/Details.vue'),
  //   props: true
  // },
  {
    path: '/apps/settlements/all',
    name: 'settlements-all',
    component: () => import('@/views/apps/settlements/all/Main.vue'),
  },
  {
    path: '/apps/settlements/payouts',
    name: 'settlements-payouts',
    component: () => import('@/views/apps/settlements/payouts/Main.vue'),
  },
  {
    path: '/direct-charge',
    name: 'direct-charge',
    component: () => import('@/views/apps/direct-charge/Main.vue'),
  },
  {
    path: '/direct-charge/create',
    name: 'direct-charge-create',
    component: () => import('@/views/apps/direct-charge/Create.vue'),
  },
  {
    path: '/payment-links',
    name: 'payment-links',
    component: () => import('@/views/apps/merchant/paylinks/Main.vue'),
  },
  {
    path: '/payment-links/create',
    name: 'payment-links-create',
    component: () => import('@/views/apps/merchant/paylinks/Create.vue'),
  },
  {
    path: '/payment-links/view',
    name: 'payment-links-view',
    component: () => import('@/views/apps/merchant/paylinks/View.vue'),
  },
  {
    path: '/payment-links/payments',
    name: 'payment-links-payments',
    component: () => import('@/views/apps/merchant/paylinks/Payments.vue'),
  },
  {
    path: '/pay/:id',
    name: 'pay',
    component: () => import('@/views/apps/pay/Main.vue'),
  },
  {
    path: '/payment-links/edit/:id',
    name: 'payment-links-edit',
    component: () => import('@/views/apps/merchant/paylinks/Create.vue'),
  },
  {
    path: '/merchant/payment-authorizations',
    name: 'merchant-payment-authorizations',
    component: () => import('@/views/apps/merchant/payauthorizations/Main.vue'),
  },
  {
    path: '/customers',
    name: 'merchant-customers',
    component: () => import('@/views/apps/merchant/customers/Main.vue'),
  },
  {
    path: '/account/merchant-accounts',
    name: 'account-merchant-sub-accounts',
    component: () => import('@/views/apps/merchant/accounts/Main.vue'),
  },
  {
    path: '/settlements/:id',
    name: 'merchant-settlements-view',
    component: () => import('@/views/apps/merchant/settlements/View.vue'),
  },
  {
    path: '/settlements',
    name: 'merchant-settlements',
    component: () => import('@/views/apps/merchant/settlements/Main.vue'),
  },
  {
    path: '/settings/settlement',
    name: 'settings-settlement',
    component: () => import('@/views/apps/settlement-accounts/Main.vue'),
  },
  {
    path: '/settings/auto-settlement',
    name: 'settings-auto-settlement',
    component: () => import('@/views/apps/merchant/settings/AutomaticSettlement.vue'),
  },
  {
    path: '/settings/fees',
    name: 'settings-fees',
    component: () => import('@/views/apps/merchant/settings/Fees.vue'),
  },
  // {
  //   path: '/settings/security-settings',
  //   name: 'settings-security-settings',
  //   component: () => import('@/views/apps/merchant/settings/Security.vue'),
  // },
  {
    path: '/settings/payment-options',
    redirect: '/settings/payment-settings'
  },
  {
    path: '/settings/payment-settings',
    name: 'settings-payment-settings',
    component: () => import('@/views/apps/merchant/settings/PaymentSettings.vue'),
  },
  {
    path: '/settings/customer-payment-options',
    name: 'settings-customer-payment-options',
    component: () => import('@/views/apps/merchant/settings/CustomerPaymentOptions.vue'),
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/apps/merchant/team/Main.vue'),
  },
  {
    path: '/apps/developer/api',
    name: 'developer-api',
    component: () => import('@/views/apps/developer/api/Main.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/apps/reports/Main.vue'),
  },


  // *===============================================---*
  // *--------- Transactions ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/transactions/list',
    name: 'transactions-list',
    component: () => import('@/views/apps/transactions/transactions-list/TransactionsList.vue'),
  },
  {
    path: '/transactions/account-statement',
    name: 'transactions-account-statement',
    component: () => import('@/views/apps/transactions/all/AccountStatement.vue'),
  },
  {
    path: '/transactions/verify-third-party-transaction',
    name: 'transactions-verify-third-party-transaction',
    component: () => import('@/views/apps/transactions/all/VerifyThirdPartyTransaction.vue'),
  },
  {
    path: '/transactions/uncompleted-flows',
    name: 'transactions-uncompleted-flows',
    component: () => import('@/views/apps/transactions/all/UncompletedFlows.vue'),
  },
  {
    path: '/transactions/:id',
    name: 'transactions-preview',
    component: () => import('@/views/apps/transactions/transactions-preview/TransactionsPreview.vue'),
  },
  {
    path: '/transactions/add',
    name: 'transactions-add',
    component: () => import('@/views/apps/transactions/transactions-add/TransactionsAdd.vue'),
  },
  {
    path: '/transactions/:id',
    name: 'transactions-edit',
    component: () => import('@/views/apps/transactions/transactions-edit/TransactionsEdit.vue'),
  },
  // *===============================================---*
  // *--------- Exchange Rates ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/exchange-rate/list',
    name: 'exchange-rate-list',
    component: () => import('@/views/apps/exchange-rate/exchange-rate-list/ExchangeRateList.vue'),
  },
  {
    path: '/apps/exchange-rate/:id',
    name: 'exchange-rate-preview',
    component: () => import('@/views/apps/exchange-rate/exchange-rate-preview/ExchangeRatePreview.vue'),
  },
  {
    path: '/apps/exchange-rate/add/:id',
    name: 'exchange-rate-add',
    component: () => import('@/views/apps/exchange-rate/exchange-rate-add/ExchangeRateAdd.vue'),
  },
  {
    path: '/apps/exchange-rate/edit/:id',
    name: 'exchange-rate-edit',
    component: () => import('@/views/apps/exchange-rate/exchange-rate-edit/ExchangeRateEdit.vue'),
  }
]
