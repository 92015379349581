module.exports = {
  // TOKEN_STORAGE_KEY: "WXS_M_TOKEN_STORAGE_KEY", /
  TOKEN_STORAGE_KEY: "token", // request token
  USER_NAME_KEY: "username",

  events: {
    NEW_MESSAGE: 'fe_new_message',
    PROFILE_DATA_CHANGED: 'PROFILE_DATA_CHANGED',
    ORG_DATA_CHANGED: 'ORG_DATA_CHANGED',
    REFRESH_PROFILE_INFO: 'REFRESH_PROFILE_INFO',
    RENDER_JSON_DATA: 'RENDER_JSON_DATA',
    LOG_OUT: 'LOG_OUT',
    USER_LOGGED_IN: 'USER_LOGGED_IN',
    START_MONITORING_INACTIVITY: 'START_MONITORING_INACTIVITY'
  },
  DEFAULT_ERR_MESSAGE: 'An error occured while processing. Please retry in few minutes.'
};
